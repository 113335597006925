/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable @eslint-react/dom/no-unsafe-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-props-no-spreading */
import { useResizeObserver } from '@folklore/hooks';
import { useUrlGenerator, useRouteMatcher } from '@folklore/routes';
// import * as AppPropTypes from '../../lib/PropTypes';
import { getComponentFromName } from '@folklore/utils';
import { animated } from '@react-spring/web';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

// import { FormattedMessage } from 'react-intl';

import { useMenuPanels } from '../../hooks/useMenuPanel';
import * as AppPropTypes from '../../lib/PropTypes';

import useMainMenuPanelSpring from '../../animations/useMainMenuPanelSpring';
import { useTouchScreen } from '../../contexts/DeviceContext';
import { useBrands, useMenu, useSite } from '../../contexts/SiteContext';
import MenuHeader from '../headers/MenuHeader';
import MenuSectionList from '../lists/MenuSectionList';
import * as ListComponents from '../lists/index';
import LinksMenu from '../menus/LinksMenu';
import SearchPanel from './SearchPanel';

import styles from '../../styles/panels/main-menu-panel.module.css';

import conseilDePresse from '../../assets/img/ConseilDePresse.svg';

const propTypes = {
    brand: AppPropTypes.brand,
    searchOpened: PropTypes.bool,
    searchQuery: PropTypes.string,
    onSearchOpen: PropTypes.func,
    onSearchClose: PropTypes.func,
    onSearchChange: PropTypes.func,
    openPanel: PropTypes.func.isRequired,
    closeMenu: PropTypes.func.isRequired,
    className: PropTypes.string,
    containerRef: AppPropTypes.ref,
};

const defaultProps = {
    brand: null,
    searchOpened: false,
    searchQuery: null,
    onSearchOpen: null,
    onSearchClose: null,
    onSearchChange: null,
    className: null,
    containerRef: null,
};

function MainMenuPanel({
    brand,
    searchOpened,
    searchQuery,
    onSearchOpen,
    onSearchClose,
    onSearchChange,
    openPanel,
    closeMenu,
    className,
    containerRef,
}) {
    const { handle: siteHandle } = useSite();
    const { sections = [] } = useMenu();
    const url = useUrlGenerator();
    const menuPanels = useMenuPanels();
    const routeMatcher = useRouteMatcher();
    const brands = useBrands();
    const {
        id: brandId = null,
        slug: brandSlug = null,
        handle: brandHandle = null,
        default: brandIsDefault = false,
    } = brand || {};

    const {
        ref: resizeRef,
        entry: { contentRect: containerContentRect },
    } = useResizeObserver();
    const { height: containerHeight = null } = containerContentRect || {};
    const {
        ref: searchPanelRef,
        entry: { contentRect: searchPanelContentRect },
    } = useResizeObserver();
    const {
        ref: searchFormRef,
        entry: { contentRect: searchFormContentRect },
    } = useResizeObserver();
    const { height: searchFormHeight = null } = searchFormContentRect || {};
    const [searchPanelTop, setSearchPanelTop] = useState(0);
    useEffect(() => {
        if (searchPanelRef.current !== null) {
            setSearchPanelTop(searchPanelRef.current.getBoundingClientRect().top);
        }
    }, [searchPanelContentRect]);
    const { searchPanelStyle, sectionStyle } = useMainMenuPanelSpring({
        searchOffsetY: searchPanelTop,
        containerHeight,
        searchFormHeight,
        searchOpened,
    });

    const onClickPanelLink = useCallback(
        (e) => {
            const { pathname } = new URL(e.currentTarget.href);
            const panel =
                Object.keys(menuPanels).find((key) => {
                    const [matches = false] = routeMatcher(menuPanels[key], pathname);
                    return matches;
                }) || null;
            if (panel !== null) {
                e.preventDefault();
                openPanel(panel);
            } else {
                closeMenu();
            }
        },
        [menuPanels, routeMatcher, openPanel, closeMenu],
    );

    const onSearchFocus = useCallback(() => {
        setSearchPanelTop(searchPanelRef.current.getBoundingClientRect().top);
        if (onSearchOpen !== null) {
            onSearchOpen();
        }
    }, [onSearchOpen]);
    const onSearchCancel = useCallback(() => {
        if (onSearchClose !== null) {
            onSearchClose();
        }
    }, [onSearchClose]);

    const isTouchScreen = useTouchScreen();
    const isGrid = !isTouchScreen;

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles[brandHandle]]: brandHandle !== null,
                    [styles.searchOpened]: searchOpened,
                    [className]: className !== null,
                },
            ])}
            ref={(ref) => {
                resizeRef.current = ref;
                if (containerRef !== null) {
                    // eslint-disable-next-line no-param-reassign
                    containerRef.current = ref;
                }
            }}
        >
            <MenuHeader brand={brand} className={styles.header} />

            {sections.map(
                ({
                    id,
                    title,
                    icon,
                    list,
                    route = null,
                    className: sectionClassName = null,
                    listClassName: sectionListClassName = null,
                    withoutViewAll = false,
                    brandScoped = false,
                    query = null,
                    ...listProps
                }) => {
                    if (id === 'brands' && brands.length === 1) {
                        return null;
                    }
                    if (id === 'search') {
                        return (
                            <div
                                className={styles.searchPanelContainer}
                                style={{
                                    height: searchFormHeight,
                                }}
                                ref={searchPanelRef}
                            >
                                <animated.div
                                    className={styles.searchPanelSlider}
                                    style={searchPanelStyle}
                                >
                                    <SearchPanel
                                        opened={searchOpened}
                                        query={searchQuery}
                                        formRef={searchFormRef}
                                        onFocus={onSearchFocus}
                                        onCancel={onSearchCancel}
                                        onChange={onSearchChange}
                                        className={styles.searchPanel}
                                        contentClassName={styles.searchPanelContent}
                                    />
                                </animated.div>
                            </div>
                        );
                    }
                    const ListComponent = getComponentFromName(ListComponents, list);
                    return ListComponent !== null ? (
                        <animated.div
                            className={classNames([styles.section, sectionClassName])}
                            style={sectionStyle}
                        >
                            <MenuSectionList
                                key={`list-${id}`}
                                title={title}
                                link={route !== null ? url(route) : null}
                                icon={icon}
                                withoutViewAll={withoutViewAll}
                                onClickLink={onClickPanelLink}
                            >
                                <ListComponent
                                    presentation={isGrid ? 'grid' : 'row'}
                                    count={isGrid ? 4 : 12}
                                    columns={2}
                                    cardTheme="menu"
                                    query={
                                        brandScoped && !brandIsDefault
                                            ? {
                                                  ...query,
                                                  brand: brandId,
                                              }
                                            : query
                                    }
                                    {...listProps}
                                    className={classNames([styles.list, sectionListClassName])}
                                    itemsClassName={styles.items}
                                />
                            </MenuSectionList>
                        </animated.div>
                    ) : null;
                },
            )}

            <LinksMenu
                items={[
                    {
                        id: 'a-propos',
                        href: '/a-propos',
                        label: 'À propos',
                    },
                    {
                        id: 'contact',
                        href: '/contact',
                        label: 'Contact',
                    },
                    {
                        id: 'publicite',
                        href: '/publicite',
                        label: 'Publicité',
                    },
                ]}
                className={styles.linksMenu}
                itemClassName={styles.linkItem}
                linkClassName={styles.linkLink}
            />

            <LinksMenu
                items={[
                    {
                        id: 'privacy',
                        href: 'https://www.iubenda.com/privacy-policy/49318682',
                        label: 'Politique de confidentialité',
                        external: true,
                    },
                    {
                        id: 'cookies',
                        href: 'https://www.iubenda.com/privacy-policy/49318682/cookie-policy',
                        label: 'Politique des cookies',
                        external: true,
                    },
                ]}
                className={styles.linksMenu}
                itemClassName={styles.linkItem}
                linkClassName={styles.linkLink}
            />
            {siteHandle === 'urbania' ? (
                <div className={styles.partners}>
                    <a
                        href="https://conseildepresse.qc.ca/"
                        target="_blank"
                        className={styles.conseilDePresse}
                    >
                        <img
                            src={conseilDePresse}
                            alt="Fier membre du Conseil de Presse du Québec"
                        />
                    </a>
                </div>
            ) : null}
        </div>
    );
}

MainMenuPanel.propTypes = propTypes;
MainMenuPanel.defaultProps = defaultProps;

export default React.forwardRef((props, ref) => <MainMenuPanel {...props} containerRef={ref} />);
